@font-face {
    font-family: 'Ubuntu-Bold';
    src: url("./assets/fonts/Ubuntu/Ubuntu-Bold.ttf");
  }
  
  @font-face {
    font-family: 'Ubuntu-Regular';
    src: url("./assets/fonts/Ubuntu/Ubuntu-Regular.ttf");
  }
  
  @font-face {
    font-family: 'Ubuntu-Medium';
    src: url("./assets/fonts/Ubuntu/Ubuntu-Medium.ttf");
  }

body {
    background-color: #fff;
    background-size: cover;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-top: auto;
    position: absolute;
    margin: auto;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#__next {
  height: 100%;
}